<template>
  <div class="content">
    <PageHeader :title="$t('webchat-component.config.title')" />
    <section class="page-content container-fluid">
      <div class="card">
        <h3 class="card-header">Script</h3>
        <div class="card-body block-el">
          <div>
            {{$t('webchat-component.copy-code')}}
          </div>
          <div class="form-control" readonly>
          &lt;script src="https://widget.yup.chat" defer&gt;&lt;/script&gt;<br>
          &lt;script&gt;<br>
          var widget = YupChat();<br>
          widget.config({ color: '#f00', align: 'left', title: '{{account.name}}' });<br>
          widget.open('{{account.id}}');<br>
          &lt;/script&gt;
          </div>
        </div>
      </div>

      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.max-response-time')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              <p>{{$t('webchat-component.config.timelimit-answer')}}</p>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light py-1">
          <div class="row">
            <div class="col-md-7 p-3">
              {{$t('webchat-component.config.max-response-time')}}
            </div>
            <div class="col-md-5 extras form-inline justify-content-md-end">
              <input type="number" name="" class="form-control mb-2 mr-2">
              <select class="select_Lxzvj form-control mb-2 mr-2">
                <option value="1">{{$tc('generic-str.minute', 2)}}</option>
                <option value="2">{{$tc('generic-str.hour', 2)}}</option>
                <option value="3">{{$tc('generic-str.day', 2)}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.max-agnt-response-time')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              <p>{{$t('webchat-component.config.if-agnt-response')}}</p>
            </div>
          </div>
        </div>
        <div class="card-footer bg-light py-1">
          <div class="row">
            <div class="col-md-7 p-3">
              {{$t('webchat-component.config.max-agnt-response-time')}}
            </div>
            <div class="col-md-5 extras form-inline justify-content-md-end">
              <input type="number" name="" class="form-control mb-2 mr-2">
              <select class="select_Lxzvj form-control mb-2 mr-2">
                <option value="1">{{$tc('generic-str.minute', 2)}}</option>
                <option value="2">{{$tc('generic-str.hour', 2)}}</option>
                <option value="3">{{$tc('generic-str.day', 2)}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.hide')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.hide-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.ty-msg')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.ty-msg-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.retention')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.retention-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.agent-name')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.agent-name-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.tabulation')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.tabulation-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.transfer')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.transfer-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.file-upload')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.file-upload-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.receive-file')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.receive-file-description')}}
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <h3 class="card-header">{{$t('webchat-component.config.quick-btn')}}</h3>
        <div class="card-body block-el">
          <div class="row">
            <div class="col-md-2">
              <toggle-button />
            </div>
            <div class="col-md-10">
              {{$t('webchat-component.config.quick-btn-description')}}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import { ToggleButton } from 'vue-js-toggle-button';
import axios from '@/plugins/axios';

export default {
  name: 'Webchat',
  components: {
    PageHeader,
    ToggleButton,
  },
  data() {
    return {
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  created() {
    axios().get('webchat/sync').then((response) => {
      console.log(response);
    }).catch((error) => {
      console.error(error);
    });
  },
  mounted() {
  },
  methods: {
    editMessage(message) {
    },
  },
};
</script>

<style lang="scss">
.extras {
  .form-control {
    width: 105px !important;
  }
}
.card-body, .card-footer {
  font-size: 10pt !important;
}
div.form-control {
  height: auto !important;
}
</style>
